const iconHamburger = document.querySelector('.hamburger');
const mobMenu = document.querySelector('.mobile-menu');
const closeMobMenus = document.querySelectorAll('.close-mob-menu');
const body = document.querySelector('body');

for (const closeButton of closeMobMenus) {
  closeButton.addEventListener('click', closeMobMenu);
}

// Toggle mobile menu

iconHamburger.addEventListener('click', () => {
  mobMenu.classList.add('active');
  body.classList.add('no-scroll');
});

// Close mobile menu
mobMenu.addEventListener('click', closeMobMenu);

//

function closeMobMenu(event) {
  mobMenu.classList.remove('active');
  body.classList.remove('no-scroll');
}
